/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { AttachmentDto } from '../model/attachmentDto';
// @ts-ignore
import { CreateTreatReleaseTransportDto } from '../model/createTreatReleaseTransportDto';
// @ts-ignore
import { StartTransportDto } from '../model/startTransportDto';
// @ts-ignore
import { SubscriptionTokenDto } from '../model/subscriptionTokenDto';
// @ts-ignore
import { TransportDto } from '../model/transportDto';
// @ts-ignore
import { UpdateTransportDto } from '../model/updateTransportDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class PatientTransportService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
  * @param consumes string[] mime-types
  * @return true: consumes contains 'multipart/form-data', false: otherwise
  */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerArriveTransport(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerArriveTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerArriveTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/arrived`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerCallSpecialist(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerCallSpecialist.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerCallSpecialist.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/call-specialist`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerCancelTransport(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerCancelTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerCancelTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/cancel`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param hospitalId 
   */
  public async patientTransportControllerChangeHospital(xTENANTID: string, id: string, hospitalId: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerChangeHospital.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerChangeHospital.');
    }
    if (hospitalId === null || hospitalId === undefined) {
      throw new Error('Required parameter hospitalId was null or undefined when calling patientTransportControllerChangeHospital.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/hospital/${this.configuration.encodeParam({name: "hospitalId", value: hospitalId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerCloseTransport(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerCloseTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerCloseTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/close`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerConfirmConsentTransport(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerConfirmConsentTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerConfirmConsentTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/confirm-consent`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerConfirmTransport(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerConfirmTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerConfirmTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/confirm`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param createTreatReleaseTransportDto 
   */
  public async patientTransportControllerCreateTreatReleaseTransport(xTENANTID: string, createTreatReleaseTransportDto: CreateTreatReleaseTransportDto, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerCreateTreatReleaseTransport.');
    }
    if (createTreatReleaseTransportDto === null || createTreatReleaseTransportDto === undefined) {
      throw new Error('Required parameter createTreatReleaseTransportDto was null or undefined when calling patientTransportControllerCreateTreatReleaseTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/transport/treat-release`,
        data: createTreatReleaseTransportDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   */
  public async patientTransportControllerGetActiveTransport(xTENANTID: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerGetActiveTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/transport/active`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param fileId 
   */
  public async patientTransportControllerGetAttachment(xTENANTID: string, id: string, fileId: string, ): Promise<Blob> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerGetAttachment.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerGetAttachment.');
    }
    if (fileId === null || fileId === undefined) {
      throw new Error('Required parameter fileId was null or undefined when calling patientTransportControllerGetAttachment.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachment/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param fileId 
   */
  public async patientTransportControllerGetFile(xTENANTID: string, id: string, fileId: string, ): Promise<Blob> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerGetFile.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerGetFile.');
    }
    if (fileId === null || fileId === undefined) {
      throw new Error('Required parameter fileId was null or undefined when calling patientTransportControllerGetFile.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/file/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerGetToken(xTENANTID: string, id: string, ): Promise<SubscriptionTokenDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerGetToken.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerGetToken.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/refresh-token`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerGetTransport(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerGetTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerGetTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   */
  public async patientTransportControllerGetTransports(xTENANTID: string, ): Promise<Array<TransportDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerGetTransports.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/transport`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   */
  public async patientTransportControllerGetUserToken(xTENANTID: string, ): Promise<SubscriptionTokenDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerGetUserToken.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/transport/refresh-token`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerInTransitTransport(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerInTransitTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerInTransitTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/in-transit`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerMoveTreatReleaseToRequested(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerMoveTreatReleaseToRequested.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerMoveTreatReleaseToRequested.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/treat-release-into-requested`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param fileId 
   */
  public async patientTransportControllerRemoveAttachment(xTENANTID: string, id: string, fileId: string, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerRemoveAttachment.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerRemoveAttachment.');
    }
    if (fileId === null || fileId === undefined) {
      throw new Error('Required parameter fileId was null or undefined when calling patientTransportControllerRemoveAttachment.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachment/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param fileId 
   */
  public async patientTransportControllerRemoveFile(xTENANTID: string, id: string, fileId: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerRemoveFile.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerRemoveFile.');
    }
    if (fileId === null || fileId === undefined) {
      throw new Error('Required parameter fileId was null or undefined when calling patientTransportControllerRemoveFile.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/file/${this.configuration.encodeParam({name: "fileId", value: fileId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerStartChat(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerStartChat.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerStartChat.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/start-chat`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param startTransportDto 
   */
  public async patientTransportControllerStartTransport(xTENANTID: string, startTransportDto: StartTransportDto, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerStartTransport.');
    }
    if (startTransportDto === null || startTransportDto === undefined) {
      throw new Error('Required parameter startTransportDto was null or undefined when calling patientTransportControllerStartTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/transport`,
        data: startTransportDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTransportControllerStartVideo(xTENANTID: string, id: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerStartVideo.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerStartVideo.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/start-video`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param updateTransportDto 
   */
  public async patientTransportControllerUpdateTransport(xTENANTID: string, id: string, updateTransportDto: UpdateTransportDto, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerUpdateTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerUpdateTransport.');
    }
    if (updateTransportDto === null || updateTransportDto === undefined) {
      throw new Error('Required parameter updateTransportDto was null or undefined when calling patientTransportControllerUpdateTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: updateTransportDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param file 
   * @param name 
   */
  public async patientTransportControllerUploadAttachment(xTENANTID: string, id: string, file: Blob, name: string, ): Promise<AttachmentDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerUploadAttachment.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerUploadAttachment.');
    }
    if (file === null || file === undefined) {
      throw new Error('Required parameter file was null or undefined when calling patientTransportControllerUploadAttachment.');
    }
    if (name === null || name === undefined) {
      throw new Error('Required parameter name was null or undefined when calling patientTransportControllerUploadAttachment.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }
      // to determine the Content-Type header
      const consumes: string[] = [
        'multipart/form-data'
      ];
    const canConsumeForm = this.canConsumeForm(consumes);
    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }
    if (file !== undefined) {
      localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
    }
    if (name !== undefined) {
      localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/attachment`,
          data: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param file 
   * @param name 
   */
  public async patientTransportControllerUploadFile(xTENANTID: string, id: string, file: Blob, name: string, ): Promise<TransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTransportControllerUploadFile.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTransportControllerUploadFile.');
    }
    if (file === null || file === undefined) {
      throw new Error('Required parameter file was null or undefined when calling patientTransportControllerUploadFile.');
    }
    if (name === null || name === undefined) {
      throw new Error('Required parameter name was null or undefined when calling patientTransportControllerUploadFile.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }
      // to determine the Content-Type header
      const consumes: string[] = [
        'multipart/form-data'
      ];
    const canConsumeForm = this.canConsumeForm(consumes);
    let localVarFormParams: { append(param: string, value: any): any; };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }
    if (file !== undefined) {
      localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
    }
    if (name !== undefined) {
      localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/transport/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/file`,
          data: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
