/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { ConsumeVerificationCodeDto } from '../model/consumeVerificationCodeDto';
// @ts-ignore
import { CreatePatientAssociationMsDto } from '../model/createPatientAssociationMsDto';
// @ts-ignore
import { CreatePatientInsuranceDto } from '../model/createPatientInsuranceDto';
// @ts-ignore
import { DependentDto } from '../model/dependentDto';
// @ts-ignore
import { FoundPatientMgmtDto } from '../model/foundPatientMgmtDto';
// @ts-ignore
import { LocationSearchInfoDto } from '../model/locationSearchInfoDto';
// @ts-ignore
import { ManagedPatientProfileDto } from '../model/managedPatientProfileDto';
// @ts-ignore
import { MatchExistingPatientDto } from '../model/matchExistingPatientDto';
// @ts-ignore
import { MatchExistingResponseDto } from '../model/matchExistingResponseDto';
// @ts-ignore
import { MembershipDetailsDto } from '../model/membershipDetailsDto';
// @ts-ignore
import { PatientAssociationDto } from '../model/patientAssociationDto';
// @ts-ignore
import { PatientAssociationMsDto } from '../model/patientAssociationMsDto';
// @ts-ignore
import { PatientInsuranceDto } from '../model/patientInsuranceDto';
// @ts-ignore
import { PatientSearchDto } from '../model/patientSearchDto';
// @ts-ignore
import { RegisterPatientDto } from '../model/registerPatientDto';
// @ts-ignore
import { RegisterPatientResponseMgmtDto } from '../model/registerPatientResponseMgmtDto';
// @ts-ignore
import { RequestTransferAccessDto } from '../model/requestTransferAccessDto';
// @ts-ignore
import { RequestTransferAccessResponseDto } from '../model/requestTransferAccessResponseDto';
// @ts-ignore
import { SendSignInInviteDto } from '../model/sendSignInInviteDto';
// @ts-ignore
import { SendVerificationCodeDto } from '../model/sendVerificationCodeDto';
// @ts-ignore
import { UpdatePatientInsuranceDto } from '../model/updatePatientInsuranceDto';
// @ts-ignore
import { UpdatePatientPhoneEmailDto } from '../model/updatePatientPhoneEmailDto';
// @ts-ignore
import { UpdatePatientProfileDto } from '../model/updatePatientProfileDto';
// @ts-ignore
import { VerificationCodeResponseDto } from '../model/verificationCodeResponseDto';
// @ts-ignore
import { VisitSearchResultDto } from '../model/visitSearchResultDto';
// @ts-ignore
import { VisitsRequestDto } from '../model/visitsRequestDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class PatientManagementService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param createPatientInsuranceDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerAddPatientInsurance(createPatientInsuranceDto: CreatePatientInsuranceDto, xTENANTID?: string, ): Promise<PatientInsuranceDto> {
    if (createPatientInsuranceDto === null || createPatientInsuranceDto === undefined) {
      throw new Error('Required parameter createPatientInsuranceDto was null or undefined when calling patientMgmtControllerAddPatientInsurance.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt//add-patient-insurance`,
        data: createPatientInsuranceDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param createPatientAssociationMsDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerAssociateSamePhoneEmail(createPatientAssociationMsDto: CreatePatientAssociationMsDto, xTENANTID?: string, ): Promise<any> {
    if (createPatientAssociationMsDto === null || createPatientAssociationMsDto === undefined) {
      throw new Error('Required parameter createPatientAssociationMsDto was null or undefined when calling patientMgmtControllerAssociateSamePhoneEmail.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/associate-same-phone-email`,
        data: createPatientAssociationMsDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param xTENANTID 
   */
  public async patientMgmtControllerCanResetQuestions(patientId: string, xTENANTID?: string, ): Promise<boolean> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerCanResetQuestions.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/can-reset-questions`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param consumeVerificationCodeDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerConsumeVerificationCode(consumeVerificationCodeDto: ConsumeVerificationCodeDto, xTENANTID?: string, ): Promise<VerificationCodeResponseDto> {
    if (consumeVerificationCodeDto === null || consumeVerificationCodeDto === undefined) {
      throw new Error('Required parameter consumeVerificationCodeDto was null or undefined when calling patientMgmtControllerConsumeVerificationCode.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/consume-verification-code`,
        data: consumeVerificationCodeDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param xTENANTID 
   */
  public async patientMgmtControllerGetConnectedAccounts(patientId: string, xTENANTID?: string, ): Promise<Array<DependentDto>> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerGetConnectedAccounts.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/connected-accounts`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   */
  public async patientMgmtControllerGetLocations(xTENANTID?: string, ): Promise<Array<LocationSearchInfoDto>> {

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/locations`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param xTENANTID 
   */
  public async patientMgmtControllerGetMembershipDetails(patientId: string, xTENANTID?: string, ): Promise<MembershipDetailsDto> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerGetMembershipDetails.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/membership-details`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param xTENANTID 
   */
  public async patientMgmtControllerGetPatientInsurance(patientId: string, xTENANTID?: string, ): Promise<Array<PatientInsuranceDto>> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerGetPatientInsurance.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/patient-insurance-details`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param xTENANTID 
   */
  public async patientMgmtControllerGetPersonalInfo(patientId: string, xTENANTID?: string, ): Promise<ManagedPatientProfileDto> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerGetPersonalInfo.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/patient-details`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param xTENANTID 
   */
  public async patientMgmtControllerGetPhoto(patientId: string, xTENANTID?: string, ): Promise<Blob> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerGetPhoto.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/photo`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param email 
   * @param xTENANTID 
   */
  public async patientMgmtControllerMatchToExistingEmail(email: string, xTENANTID?: string, ): Promise<MatchExistingResponseDto> {
    if (email === null || email === undefined) {
      throw new Error('Required parameter email was null or undefined when calling patientMgmtControllerMatchToExistingEmail.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "email", value: email, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/match-existing-email`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param matchExistingPatientDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerMatchToExistingPatient(matchExistingPatientDto: MatchExistingPatientDto, xTENANTID?: string, ): Promise<MatchExistingResponseDto> {
    if (matchExistingPatientDto === null || matchExistingPatientDto === undefined) {
      throw new Error('Required parameter matchExistingPatientDto was null or undefined when calling patientMgmtControllerMatchToExistingPatient.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/match-existing-patient`,
        data: matchExistingPatientDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param phone 
   * @param xTENANTID 
   */
  public async patientMgmtControllerMatchToExistingPhone(phone: string, xTENANTID?: string, ): Promise<MatchExistingResponseDto> {
    if (phone === null || phone === undefined) {
      throw new Error('Required parameter phone was null or undefined when calling patientMgmtControllerMatchToExistingPhone.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "phone", value: phone, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/match-existing-phone`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param xTENANTID 
   */
  public async patientMgmtControllerRefreshPatientInsuranceFromEme(patientId: string, xTENANTID?: string, ): Promise<any> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerRefreshPatientInsuranceFromEme.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/refresh-from-eme`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param registerPatientDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerRegisterPatient(registerPatientDto: RegisterPatientDto, xTENANTID?: string, ): Promise<RegisterPatientResponseMgmtDto> {
    if (registerPatientDto === null || registerPatientDto === undefined) {
      throw new Error('Required parameter registerPatientDto was null or undefined when calling patientMgmtControllerRegisterPatient.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/register-patient`,
        data: registerPatientDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientAssociationMsDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerRemoveConnectedAccount(patientAssociationMsDto: PatientAssociationMsDto, xTENANTID?: string, ): Promise<PatientAssociationDto> {
    if (patientAssociationMsDto === null || patientAssociationMsDto === undefined) {
      throw new Error('Required parameter patientAssociationMsDto was null or undefined when calling patientMgmtControllerRemoveConnectedAccount.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/patient-association/remove-connected-account`,
        data: patientAssociationMsDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param requestTransferAccessDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerRequestTransferAccess(patientId: string, requestTransferAccessDto: RequestTransferAccessDto, xTENANTID?: string, ): Promise<RequestTransferAccessResponseDto> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerRequestTransferAccess.');
    }
    if (requestTransferAccessDto === null || requestTransferAccessDto === undefined) {
      throw new Error('Required parameter requestTransferAccessDto was null or undefined when calling patientMgmtControllerRequestTransferAccess.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/request-transfer-access`,
        data: requestTransferAccessDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param xTENANTID 
   */
  public async patientMgmtControllerResetQuestions(patientId: string, xTENANTID?: string, ): Promise<any> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerResetQuestions.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/reset-questions`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientSearchDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerSearchPatient(patientSearchDto: PatientSearchDto, xTENANTID?: string, ): Promise<Array<FoundPatientMgmtDto>> {
    if (patientSearchDto === null || patientSearchDto === undefined) {
      throw new Error('Required parameter patientSearchDto was null or undefined when calling patientMgmtControllerSearchPatient.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/search`,
        data: patientSearchDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param visitsRequestDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerSearchVisits(visitsRequestDto: VisitsRequestDto, xTENANTID?: string, ): Promise<Array<VisitSearchResultDto>> {
    if (visitsRequestDto === null || visitsRequestDto === undefined) {
      throw new Error('Required parameter visitsRequestDto was null or undefined when calling patientMgmtControllerSearchVisits.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/search-visits`,
        data: visitsRequestDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param sendSignInInviteDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerSendInviteLink(sendSignInInviteDto: SendSignInInviteDto, xTENANTID?: string, ): Promise<any> {
    if (sendSignInInviteDto === null || sendSignInInviteDto === undefined) {
      throw new Error('Required parameter sendSignInInviteDto was null or undefined when calling patientMgmtControllerSendInviteLink.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/send-invite-link`,
        data: sendSignInInviteDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param sendVerificationCodeDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerSendVerificationCode(sendVerificationCodeDto: SendVerificationCodeDto, xTENANTID?: string, ): Promise<VerificationCodeResponseDto> {
    if (sendVerificationCodeDto === null || sendVerificationCodeDto === undefined) {
      throw new Error('Required parameter sendVerificationCodeDto was null or undefined when calling patientMgmtControllerSendVerificationCode.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/send-verification-code`,
        data: sendVerificationCodeDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param insuranceId 
   * @param updatePatientInsuranceDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerUpdatePatientInsurance(insuranceId: string, updatePatientInsuranceDto: UpdatePatientInsuranceDto, xTENANTID?: string, ): Promise<PatientInsuranceDto> {
    if (insuranceId === null || insuranceId === undefined) {
      throw new Error('Required parameter insuranceId was null or undefined when calling patientMgmtControllerUpdatePatientInsurance.');
    }
    if (updatePatientInsuranceDto === null || updatePatientInsuranceDto === undefined) {
      throw new Error('Required parameter updatePatientInsuranceDto was null or undefined when calling patientMgmtControllerUpdatePatientInsurance.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/patient-insurance-details/${this.configuration.encodeParam({name: "insuranceId", value: insuranceId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: updatePatientInsuranceDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param updatePatientProfileDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerUpdatePersonalInfo(patientId: string, updatePatientProfileDto: UpdatePatientProfileDto, xTENANTID?: string, ): Promise<ManagedPatientProfileDto> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerUpdatePersonalInfo.');
    }
    if (updatePatientProfileDto === null || updatePatientProfileDto === undefined) {
      throw new Error('Required parameter updatePatientProfileDto was null or undefined when calling patientMgmtControllerUpdatePersonalInfo.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/patient-details`,
        data: updatePatientProfileDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param patientId 
   * @param updatePatientPhoneEmailDto 
   * @param xTENANTID 
   */
  public async patientMgmtControllerUpdatePhoneEmail(patientId: string, updatePatientPhoneEmailDto: UpdatePatientPhoneEmailDto, xTENANTID?: string, ): Promise<ManagedPatientProfileDto> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling patientMgmtControllerUpdatePhoneEmail.');
    }
    if (updatePatientPhoneEmailDto === null || updatePatientPhoneEmailDto === undefined) {
      throw new Error('Required parameter updatePatientPhoneEmailDto was null or undefined when calling patientMgmtControllerUpdatePhoneEmail.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/v1/patient-mgmt/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/patient-phone-email`,
        data: updatePatientPhoneEmailDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
