/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientIdentifierDto } from './patientIdentifierDto';
import { SexAssigned } from './sexAssigned';
import { PatientStatus } from './patientStatus';
import { TenantPatientDto } from './tenantPatientDto';


export interface FoundPatientMgmtDto { 
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string;
    suffix?: string;
    sexAssignedAtBirth?: SexAssigned;
    status: PatientStatus;
    dateOfBirth?: string;
    email?: string;
    phone?: string;
    language?: string;
    photo?: string;
    address?: string;
    address2?: string;
    cityTown?: string;
    state?: string;
    zip?: string;
    personId?: string;
    metadata?: any;
    identifiers?: Array<PatientIdentifierDto>;
    tenantPatients?: Array<TenantPatientDto>;
    pronouns?: string;
    mrn?: string;
    enrollmentStatus?: FoundPatientMgmtDto.EnrollmentStatusEnum;
    insurance?: string;
    isPrime?: boolean;
}
export namespace FoundPatientMgmtDto {
    export type EnrollmentStatusEnum = 'All' | 'Current' | 'Pending' | 'Prime';
    export const EnrollmentStatusEnum = {
        All: 'All' as EnrollmentStatusEnum,
        Current: 'Current' as EnrollmentStatusEnum,
        Pending: 'Pending' as EnrollmentStatusEnum,
        Prime: 'Prime' as EnrollmentStatusEnum
    };
}


