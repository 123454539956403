/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SendSignInInviteDto { 
    /**
     * The unique identifier of the patient.
     */
    patientId: string;
    /**
     * The type of invite to send. Can be either email or SMS.
     */
    inviteType: SendSignInInviteDto.InviteTypeEnum;
}
export namespace SendSignInInviteDto {
    export type InviteTypeEnum = 'email' | 'sms' | 'email-sms';
    export const InviteTypeEnum = {
        Email: 'email' as InviteTypeEnum,
        Sms: 'sms' as InviteTypeEnum,
        EmailSms: 'email-sms' as InviteTypeEnum
    };
}


