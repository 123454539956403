/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { CreatePatientExternalDto } from '../model/createPatientExternalDto';
// @ts-ignore
import { PatientDto } from '../model/patientDto';
// @ts-ignore
import { SyncDto } from '../model/syncDto';
// @ts-ignore
import { UpdatePatientExternalDto } from '../model/updatePatientExternalDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class PatientExternalAPISService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param createPatientExternalDto 
   */
  public async patientExternalControllerCreateOrFindPatient(createPatientExternalDto: CreatePatientExternalDto, ): Promise<Array<PatientDto>> {
    if (createPatientExternalDto === null || createPatientExternalDto === undefined) {
      throw new Error('Required parameter createPatientExternalDto was null or undefined when calling patientExternalControllerCreateOrFindPatient.');
    }

    const localVarHeaders = this.defaultHeaders;

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-external`,
        data: createPatientExternalDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param pmPatientId 
   */
  public async patientExternalControllerGetPatientByPmId(pmPatientId: string, ): Promise<any> {
    if (pmPatientId === null || pmPatientId === undefined) {
      throw new Error('Required parameter pmPatientId was null or undefined when calling patientExternalControllerGetPatientByPmId.');
    }

    const localVarHeaders = this.defaultHeaders;

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-external/get-patient-by-pm-id/${this.configuration.encodeParam({name: "pmPatientId", value: pmPatientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param syncDto 
   */
  public async patientExternalControllerSyncInsurances(syncDto: SyncDto, ): Promise<any> {
    if (syncDto === null || syncDto === undefined) {
      throw new Error('Required parameter syncDto was null or undefined when calling patientExternalControllerSyncInsurances.');
    }

    const localVarHeaders = this.defaultHeaders;

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-external/sync-with-eme`,
        data: syncDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param id 
   * @param updatePatientExternalDto 
   */
  public async patientExternalControllerUpdatePatient(id: string, updatePatientExternalDto: UpdatePatientExternalDto, ): Promise<Array<PatientDto>> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientExternalControllerUpdatePatient.');
    }
    if (updatePatientExternalDto === null || updatePatientExternalDto === undefined) {
      throw new Error('Required parameter updatePatientExternalDto was null or undefined when calling patientExternalControllerUpdatePatient.');
    }

    const localVarHeaders = this.defaultHeaders;

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/v1/patient-external/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: updatePatientExternalDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
