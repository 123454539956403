/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { CareOptionDto } from '../model/careOptionDto';
// @ts-ignore
import { CareOptionsDto } from '../model/careOptionsDto';
// @ts-ignore
import { PatientTriagesDto } from '../model/patientTriagesDto';
// @ts-ignore
import { RegisteredPatientDto } from '../model/registeredPatientDto';
// @ts-ignore
import { SearchTriageDto } from '../model/searchTriageDto';
// @ts-ignore
import { TriageAnswerQuestionDto } from '../model/triageAnswerQuestionDto';
// @ts-ignore
import { TriageComplaintsDto } from '../model/triageComplaintsDto';
// @ts-ignore
import { TriageDto } from '../model/triageDto';
// @ts-ignore
import { TriageReportDto } from '../model/triageReportDto';
// @ts-ignore
import { TriageSymptomDto } from '../model/triageSymptomDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class PatientTriageService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param xTENANTID 
   * @param careOptionDto 
   */
  public async patientTriageControllerAddOrUpdateCareOption(xTENANTID: string, careOptionDto: CareOptionDto, ): Promise<CareOptionDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerAddOrUpdateCareOption.');
    }
    if (careOptionDto === null || careOptionDto === undefined) {
      throw new Error('Required parameter careOptionDto was null or undefined when calling patientTriageControllerAddOrUpdateCareOption.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/triage/care-options`,
        data: careOptionDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param careOptionsDto 
   */
  public async patientTriageControllerAddOrUpdateCareOptions(xTENANTID: string, careOptionsDto: CareOptionsDto, ): Promise<Array<CareOptionDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerAddOrUpdateCareOptions.');
    }
    if (careOptionsDto === null || careOptionsDto === undefined) {
      throw new Error('Required parameter careOptionsDto was null or undefined when calling patientTriageControllerAddOrUpdateCareOptions.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/triage/care-options-array`,
        data: careOptionsDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param triageAnswerQuestionDto 
   */
  public async patientTriageControllerAnswerTriage(xTENANTID: string, id: string, triageAnswerQuestionDto: TriageAnswerQuestionDto, ): Promise<TriageDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerAnswerTriage.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTriageControllerAnswerTriage.');
    }
    if (triageAnswerQuestionDto === null || triageAnswerQuestionDto === undefined) {
      throw new Error('Required parameter triageAnswerQuestionDto was null or undefined when calling patientTriageControllerAnswerTriage.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/triage/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/answer`,
        data: triageAnswerQuestionDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTriageControllerCancelTriage(xTENANTID: string, id: string, ): Promise<TriageDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerCancelTriage.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTriageControllerCancelTriage.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/patient/triage/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/cancel`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientTriageControllerCloseTriage(xTENANTID: string, id: string, ): Promise<TriageDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerCloseTriage.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTriageControllerCloseTriage.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/triage/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/close`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param careTypes 
   */
  public async patientTriageControllerDeleteCareOption(xTENANTID: string, careTypes?: Array<string>, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerDeleteCareOption.');
    }

    let localVarQueryParameters: any = {};
      if (careTypes) {
        careTypes.forEach((element) => {
          localVarQueryParameters['careTypes'] = element;
        })
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/patient/triage/care-options`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param careTypes 
   */
  public async patientTriageControllerGetCareOptions(xTENANTID: string, careTypes?: Array<string>, ): Promise<Array<CareOptionDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerGetCareOptions.');
    }

    let localVarQueryParameters: any = {};
      if (careTypes) {
        careTypes.forEach((element) => {
          localVarQueryParameters['careTypes'] = element;
        })
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/triage/care-options`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param clinicId 
   */
  public async patientTriageControllerGetCompletedTriageDetails(xTENANTID: string, id: string, clinicId: string, ): Promise<TriageReportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerGetCompletedTriageDetails.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTriageControllerGetCompletedTriageDetails.');
    }
    if (clinicId === null || clinicId === undefined) {
      throw new Error('Required parameter clinicId was null or undefined when calling patientTriageControllerGetCompletedTriageDetails.');
    }

    let localVarQueryParameters: any = {};
    if (clinicId !== undefined && clinicId !== null) {
      localVarQueryParameters['clinicId'] = clinicId;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/triage/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/report-details`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param clinicId 
   */
  public async patientTriageControllerGetHistory(xTENANTID: string, id: string, clinicId: string, ): Promise<PatientTriagesDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerGetHistory.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTriageControllerGetHistory.');
    }
    if (clinicId === null || clinicId === undefined) {
      throw new Error('Required parameter clinicId was null or undefined when calling patientTriageControllerGetHistory.');
    }

    let localVarQueryParameters: any = {};
    if (clinicId !== undefined && clinicId !== null) {
      localVarQueryParameters['clinicId'] = clinicId;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/triage/patient/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/history`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param tags 
   * @param excludeTags 
   */
  public async patientTriageControllerGetSymptoms(xTENANTID: string, tags?: string, excludeTags?: string, ): Promise<Array<TriageSymptomDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerGetSymptoms.');
    }

    let localVarQueryParameters: any = {};
    if (tags !== undefined && tags !== null) {
      localVarQueryParameters['tags'] = tags;
    }
    if (excludeTags !== undefined && excludeTags !== null) {
      localVarQueryParameters['excludeTags'] = excludeTags;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/triage/symptoms`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   */
  public async patientTriageControllerGetTriage(xTENANTID: string, ): Promise<TriageDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerGetTriage.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/triage/get-active`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param frontDoorId 
   */
  public async patientTriageControllerRegisterTriage(xTENANTID: string, id: string, frontDoorId: string, ): Promise<RegisteredPatientDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerRegisterTriage.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTriageControllerRegisterTriage.');
    }
    if (frontDoorId === null || frontDoorId === undefined) {
      throw new Error('Required parameter frontDoorId was null or undefined when calling patientTriageControllerRegisterTriage.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/triage/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/register/${this.configuration.encodeParam({name: "frontDoorId", value: frontDoorId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param description 
   */
  public async patientTriageControllerRetrieveComplaint(xTENANTID: string, description: string, ): Promise<TriageComplaintsDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerRetrieveComplaint.');
    }
    if (description === null || description === undefined) {
      throw new Error('Required parameter description was null or undefined when calling patientTriageControllerRetrieveComplaint.');
    }

    let localVarQueryParameters: any = {};
    if (description !== undefined && description !== null) {
      localVarQueryParameters['description'] = description;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/triage/triage-complaints`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param searchTriageDto 
   */
  public async patientTriageControllerSearchCompletedTriages(xTENANTID: string, searchTriageDto: SearchTriageDto, ): Promise<Array<TriageReportDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerSearchCompletedTriages.');
    }
    if (searchTriageDto === null || searchTriageDto === undefined) {
      throw new Error('Required parameter searchTriageDto was null or undefined when calling patientTriageControllerSearchCompletedTriages.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/triage/report`,
        data: searchTriageDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param complaintId 
   * @param complaintName 
   * @param description 
   */
  public async patientTriageControllerStartNewTriage(xTENANTID: string, complaintId: number, complaintName: string, description: string, ): Promise<TriageDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerStartNewTriage.');
    }
    if (complaintId === null || complaintId === undefined) {
      throw new Error('Required parameter complaintId was null or undefined when calling patientTriageControllerStartNewTriage.');
    }
    if (complaintName === null || complaintName === undefined) {
      throw new Error('Required parameter complaintName was null or undefined when calling patientTriageControllerStartNewTriage.');
    }
    if (description === null || description === undefined) {
      throw new Error('Required parameter description was null or undefined when calling patientTriageControllerStartNewTriage.');
    }

    let localVarQueryParameters: any = {};
    if (complaintName !== undefined && complaintName !== null) {
      localVarQueryParameters['complaintName'] = complaintName;
    }
    if (description !== undefined && description !== null) {
      localVarQueryParameters['description'] = description;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/triage/start-new/${this.configuration.encodeParam({name: "complaintId", value: complaintId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param complaintId 
   * @param complaintName 
   */
  public async patientTriageControllerStartTriage(xTENANTID: string, id: string, complaintId: number, complaintName: string, ): Promise<TriageDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientTriageControllerStartTriage.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientTriageControllerStartTriage.');
    }
    if (complaintId === null || complaintId === undefined) {
      throw new Error('Required parameter complaintId was null or undefined when calling patientTriageControllerStartTriage.');
    }
    if (complaintName === null || complaintName === undefined) {
      throw new Error('Required parameter complaintName was null or undefined when calling patientTriageControllerStartTriage.');
    }

    let localVarQueryParameters: any = {};
    if (complaintName !== undefined && complaintName !== null) {
      localVarQueryParameters['complaintName'] = complaintName;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/triage/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/start/${this.configuration.encodeParam({name: "complaintId", value: complaintId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`,
        params: localVarQueryParameters,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
