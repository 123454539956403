/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientAssociationRelationship } from './patientAssociationRelationship';


export interface PatientAssociationDto { 
    id: string;
    sharedWithPatientId: string;
    sharedByPatientId: string;
    senderId: string;
    approverId: string;
    status: PatientAssociationDto.StatusEnum;
    relationship: PatientAssociationRelationship;
    metadata?: any;
}
export namespace PatientAssociationDto {
    export type StatusEnum = 'pending' | 'approved' | 'declined';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Approved: 'approved' as StatusEnum,
        Declined: 'declined' as StatusEnum
    };
}


