/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { GetTokenDataDto } from '../model/getTokenDataDto';
// @ts-ignore
import { OnInitPayloadDto } from '../model/onInitPayloadDto';
// @ts-ignore
import { OnTransferPayloadDto } from '../model/onTransferPayloadDto';
// @ts-ignore
import { OnUpdatePayloadDto } from '../model/onUpdatePayloadDto';
// @ts-ignore
import { SavePatientPmDto } from '../model/savePatientPmDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class PatientOrchestratorService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param xTENANTID 
   * @param savePatientPmDto 
   */
  public async patientOrchestratorControllerCreatePatientPM(xTENANTID: string, savePatientPmDto: SavePatientPmDto, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientOrchestratorControllerCreatePatientPM.');
    }
    if (savePatientPmDto === null || savePatientPmDto === undefined) {
      throw new Error('Required parameter savePatientPmDto was null or undefined when calling patientOrchestratorControllerCreatePatientPM.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/patient-orchestrator/save-patient-pm`,
        data: savePatientPmDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param getTokenDataDto 
   */
  public async patientOrchestratorControllerGetTokenData(xTENANTID: string, getTokenDataDto: GetTokenDataDto, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientOrchestratorControllerGetTokenData.');
    }
    if (getTokenDataDto === null || getTokenDataDto === undefined) {
      throw new Error('Required parameter getTokenDataDto was null or undefined when calling patientOrchestratorControllerGetTokenData.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/patient-orchestrator/get-token-data`,
        data: getTokenDataDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param onInitPayloadDto 
   */
  public async patientOrchestratorControllerOnInit(xTENANTID: string, onInitPayloadDto: OnInitPayloadDto, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientOrchestratorControllerOnInit.');
    }
    if (onInitPayloadDto === null || onInitPayloadDto === undefined) {
      throw new Error('Required parameter onInitPayloadDto was null or undefined when calling patientOrchestratorControllerOnInit.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/patient-orchestrator/on-init`,
        data: onInitPayloadDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param onTransferPayloadDto 
   */
  public async patientOrchestratorControllerOnTransfer(xTENANTID: string, onTransferPayloadDto: OnTransferPayloadDto, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientOrchestratorControllerOnTransfer.');
    }
    if (onTransferPayloadDto === null || onTransferPayloadDto === undefined) {
      throw new Error('Required parameter onTransferPayloadDto was null or undefined when calling patientOrchestratorControllerOnTransfer.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/patient-orchestrator/on-transfer`,
        data: onTransferPayloadDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param onUpdatePayloadDto 
   */
  public async patientOrchestratorControllerOnUpdate(xTENANTID: string, onUpdatePayloadDto: OnUpdatePayloadDto, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientOrchestratorControllerOnUpdate.');
    }
    if (onUpdatePayloadDto === null || onUpdatePayloadDto === undefined) {
      throw new Error('Required parameter onUpdatePayloadDto was null or undefined when calling patientOrchestratorControllerOnUpdate.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/patient-orchestrator/on-update`,
        data: onUpdatePayloadDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
