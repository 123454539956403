/**
 * Patient service
 * The patient service API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { CreatePatientInsuranceDto } from '../model/createPatientInsuranceDto';
// @ts-ignore
import { PatientInsuranceDto } from '../model/patientInsuranceDto';
// @ts-ignore
import { ScanImageDto } from '../model/scanImageDto';
// @ts-ignore
import { UpdatePatientInsuranceDto } from '../model/updatePatientInsuranceDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class PatientInsuranceService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param xTENANTID 
   * @param createPatientInsuranceDto 
   */
  public async patientInsuranceControllerAddInsurance(xTENANTID: string, createPatientInsuranceDto: CreatePatientInsuranceDto, ): Promise<PatientInsuranceDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientInsuranceControllerAddInsurance.');
    }
    if (createPatientInsuranceDto === null || createPatientInsuranceDto === undefined) {
      throw new Error('Required parameter createPatientInsuranceDto was null or undefined when calling patientInsuranceControllerAddInsurance.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-insurance`,
        data: createPatientInsuranceDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientInsuranceControllerGetInsurance(xTENANTID: string, id: string, ): Promise<PatientInsuranceDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientInsuranceControllerGetInsurance.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientInsuranceControllerGetInsurance.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-insurance/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   */
  public async patientInsuranceControllerGetInsurancesForPatient(xTENANTID: string, ): Promise<Array<PatientInsuranceDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientInsuranceControllerGetInsurancesForPatient.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/patient/v1/patient-insurance`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   */
  public async patientInsuranceControllerRemove(xTENANTID: string, id: string, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientInsuranceControllerRemove.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientInsuranceControllerRemove.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/patient/v1/patient-insurance/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param scanImageDto 
   */
  public async patientInsuranceControllerScanInsuranceCard(xTENANTID: string, scanImageDto: ScanImageDto, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientInsuranceControllerScanInsuranceCard.');
    }
    if (scanImageDto === null || scanImageDto === undefined) {
      throw new Error('Required parameter scanImageDto was null or undefined when calling patientInsuranceControllerScanInsuranceCard.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/patient/v1/patient-insurance/scan-card`,
        data: scanImageDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param xTENANTID 
   * @param id 
   * @param updatePatientInsuranceDto 
   */
  public async patientInsuranceControllerUpdateInsurance(xTENANTID: string, id: string, updatePatientInsuranceDto: UpdatePatientInsuranceDto, ): Promise<PatientInsuranceDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientInsuranceControllerUpdateInsurance.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientInsuranceControllerUpdateInsurance.');
    }
    if (updatePatientInsuranceDto === null || updatePatientInsuranceDto === undefined) {
      throw new Error('Required parameter updatePatientInsuranceDto was null or undefined when calling patientInsuranceControllerUpdateInsurance.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/patient/v1/patient-insurance/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: updatePatientInsuranceDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
